<template>
  <div id="brands">
    <div class="top">
      <div class="info">
        <div class="left" v-show="false">
          <div class="tips"><div class="text">Brand story</div></div>
          <div class="d_info">
            <div class="logo"><img :src="brand.logo" :alt="brand.name" /></div>
            <div class="subinfo">{{ brand.subinfo }}</div>
            <div class="subinfo2">{{ brand.subinfo2 }}</div>
            <div class="btn">
              <button @click="toContact(brand.link)">前往官網</button>
            </div>
          </div>
        </div>
        <div class="right">
          <img
            :src="brand.dlogo"
            :alt="brand.name"
            v-if="viewDevice != 'mobile'"
          />
          <img :src="brand.m_dlogo" :alt="brand.name" v-else />
        </div>
      </div>
      <!-- <div class="canvas">
         <canvas
          ref="myCanvas"
          :height="viewDevice != 'mobile' ? '980' : '1400'"
          :width="viewDevice != 'mobile' ? '1400' : '980'"
        ></canvas>
      
      </div> -->
      <div class="img">
        <img :src="brand.build.img" :alt="brand.subinfo" />
      </div>
    </div>
    <div class="middle"><span>FEATURES</span><span> 品牌特色 </span></div>
    <div class="bottom">
      <div
        class="item"
        v-for="(item, index) in brand.info"
        :key="index"
        :class="{ even: index / 2 == 0 }"
      >
        <img :src="item.img" :alt="item.title" />
        <div class="item_info">
          <div>{{ item.title }}</div>
          <div>{{ item.desc }}</div>
        </div>
      </div>

      <button @click="toContact(brand.link)">前往官網</button>
    </div>
  </div>
</template>

<script>
import windowSize from "@/mixins/windowSize";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [windowSize],
  data() {
    return {
      stage: null,
    };
  },
  computed: {
    ...mapGetters(["brands"]),
    brand() {
      let obj = null;
      if (this.brands) {
        this.brands.forEach((element) => {
          if (element.name == this.$route.params.name) {
            obj = element;
          }
        });
      }
      return obj;
    },
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     deep: true,
  //     handler(newVal, oldVal) {
  //       if (typeof window !== "undefined") {
  //         this.$nextTick(() => {
  //           // this.initCanvas();
  //         });
  //       }
  //     },
  //   },
  // },
  // mounted() {},
  methods: {
    ...mapActions(["toContact"]),
    // initCanvas() {
    //   if (!this.brand && this.$refs.myCanvas) {
    //     return;
    //   }
    //   // 创建舞台对象
    //   this.stage = new createjs.Stage(this.$refs.myCanvas);
    //   this.stage.enableMouseOver(10);
    //   this.stage.mouseMoveOutside = true;
    //   let container = this.create_build(this.brand.build);
    //   this.stage.addChild(container);
    //   // 一直更新舞台
    //   createjs.Ticker.addEventListener("tick", this.stage);
    // },
    // create_build(item) {
    //   const container = new createjs.Container();
    //   container.originalIndex = item.index;
    //   const image = new createjs.Bitmap(item.img);
    //   // 设置图片位置和缩放比例
    //   image.x = (this.$refs.myCanvas.width - image.image.width) / 2;
    //   image.y = (this.$refs.myCanvas.height - image.image.height) / 2;
    //   // image.scaleX = image.scaleY = this.viewDevice != "mobile" ? 1 : 2.5;

    //   // 设置原始尺寸
    //   image.image.onload = () => {
    //     image.regX = image.image.width / 2;
    //     image.regY = image.image.height / 2;
    //     image.width = image.image.width;
    //     image.height = image.image.height;

    //     container.addChild(image);
    //   };
    //   // 跨域图像处理
    //   image.image.crossOrigin = "Anonymous"; // 添加这行代码

    //   return container;
    // },
  },
};
</script>

<style lang="less" scoped>
#brands {
  position: relative;
  .top {
    height: 50em;
    width: 100vw;
    background-color: @primary-color;
    .info {
      .flex-mixin(row,center,center);
      padding: 1em 12em;
      height: 100%;
      .mobile({
        .flex-mixin(column,center,center);
        overflow: hidden;
        padding: 0;
      });
      .left {
        flex: 1;
        background-color: #fee44d;
        border-radius: 50%;
        aspect-ratio: 1/1;
        .flex-mixin(row,center,center);
        overflow: hidden;
        .web({
          min-width: 28em;
        });
        .tablet({
          position: absolute;
          max-width: 30em;
          left: 10em;
        });
        .mobile({
          position: relative;
          border-radius: 0 0 50% 50%;
          z-index: 1;
          width: 140%;
          aspect-ratio: 1/1;
          padding: 1em;
          flex: 2

        });
        .tips {
          display: inline-block;
          position: relative; /* 用于定位内部的垂直文本 */
          height: 20%;
          flex: 1;
          top: -3em;
          left: 3em;
          .mobile({
            height: 25%;
          });
          &::after {
            content: "";
            position: absolute;
            left: 50%; /* 位于容器的中间 */
            top: 0;
            bottom: 0;
            border-left: 2px solid #461200; /* 左边框 */
            transform: translateX(-50%); /* 确保边框居中 */
            .mobile({
              left: 100%;
              top: -0.1em;
            });
          }
          .text {
            position: absolute;
            left: 75%; /* 位于容器的中间 */
            top: 8%; /* 位于容器的中间 */
            transform: translate(-50%, -50%) rotate(-270deg); /* 居中并旋转文本 */
            transform-origin: left top; /* 设置变换的原点 */
            white-space: nowrap; /* 防止文本换行 */
            color: #461200;
            font-weight: bold;
            font-size: 1.2em;
          }
        }
        .d_info {
          flex: 2.5;
          .flex-mixin(column,flex-start,flex-start);
          height: 70%;
          color: #461200;
          .logo {
            text-align: left;
            min-height: 10em;
            .flex-mixin(row,flex-start,flex-end);
          }
          img {
            width: 70%;
            text-align: left;
          }
          .mobile({
            height: auto;
            text-align: left;
            img{
              width: 80%;
              text-align: left
            }
          });
          .subinfo {
            font-size: 1.3em;
            font-weight: bold;
            margin: 1em 0 0.5em;
          }
          .subinfo2 {
            margin: 0.5em 0;
            line-height: 1.5em;
            letter-spacing: 0.05em;
            padding-right: 2em;
            text-align: left;
            .mobile({
              padding-right:5em; 
            });
          }
          .btn {
            button {
              cursor: pointer;
              margin-top: 1em;
              border: 1px solid #461200;
              padding: 0.5em 2em;
              background-color: rgba(0, 0, 0, 0);
              border-radius: 2em;
              font-size: 1em;
              transition: all 0.2s linear;
              color: #461200;
              &:hover {
                background-color: #461200;
                color: white;
              }
            }
          }
        }
      }
      .right {
        flex: 1.5;
        z-index: 2;
        pointer-events: none;
        .tablet({
          position: relative;
          top: 18em;
          left: 10em;
        });
        .mobile({
          text-align: right
        });
        img {
          max-width: 90%;
        }
      }
    }
  }
  .middle {
    height: 20em;
    width: 100%;
    position: relative;
    text-align: center;
    .flex-mixin(row,center,center);
    margin-bottom: 8em;
    overflow: hidden;
    .mobile({
      height: 15em;
    });
    span:nth-child(1) {
      position: absolute;
      font-size: 10em;
      color: #fcfaf3;
      font-weight: bold;
      bottom: 0.2em;
      .mobile({
        font-size: 5em;
      });
    }
    span:nth-child(2) {
      position: absolute;
      font-size: 1.5em;
      color: #fcfaf3;
      font-weight: bold;
      color: #461200;
      letter-spacing: 1em;
      z-index: 2;
      bottom: 1em;
    }
  }
  .img {
    position: absolute;
    top: 42em;
    max-width: 100%;

    margin: 0 auto;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    .mobile({
      top: 45em;
    });
    img {
      max-width: 100%;
      margin: 0 auto;
      .mobile({
       max-width: 85%;

      });
    }
  }
  .canvas {
    position: relative;
    pointer-events: none;
    canvas {
      z-index: 11;
      position: absolute;
      top: 0%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }
  .bottom {
    button {
      margin: 10em 0;
      padding: 1.5em 6em;
      border: 0;
      background-color: #461200;
      color: white;
      cursor: pointer;
      font-size: 1em;
      transition: all 0.2s linear;
      &:hover {
        opacity: 0.9;
      }
    }
    img {
      width: 100%;
    }
    .item {
      max-height: 50em;
      overflow: hidden;
      position: relative;
      > div {
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%);
        max-width: 30%;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 2.5em;
        text-align: left;
        color: #461200;
        .mobile({
          position: unset;
          top: unset;
          bottom: unset;
          background-color: #fff;
        transform: unset;
        max-width: 100%;
          padding: 1em;
        });
        div:nth-child(1) {
          font-size: 1.5em;
          font-weight: bold;
          margin: 0em 0 0.5em;
        }
        div:nth-child(2) {
          line-height: 2em;
          letter-spacing: 0.1em;
        }
      }
    }
    .even {
      > div {
        left: 20%;
      }
    }
  }
}
</style>
